<template>
  <div class="rightsAll">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" v-if="!nodatashow">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了"  @load="onLoad">
        <div class="rights-item" v-for="(item, index) in rightsList" :key="index">
          <img :src="item.status == '已领取' ? bg : bge" class="bg-img">
          <div class="rights-content">
            <div class="title flex">
<!--              <img :src="item.status == '已领取' ? jdlogo : jdlogoe" class="logo-img">-->
              <div :class="{'title-e': item.status != '已领取'}">{{item.name}}</div>
            </div>
            <div class="code" :class="{'code-e': item.status != '已领取'}">兑换码：{{item.code}}</div>
            <div class="date" :class="{'date-e': item.status != '已领取'}">有效期至{{item.endDate}}</div>
            <van-button class="btn" :class="{'btn-e': item.status != '已领取'}"
            :disabled="item.status != '已领取'" @click="gotoUse(item.benefitId)"
            :data-clipboard-text="item.code"
            >{{item.status == '已领取' ? '复制兑换码 ，去使用' : '已过期'}}</van-button>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div v-else class="flex no-wrap">
      <img :src="nodata" alt="" style="width:100%">
      <div>暂无权益~</div>
    </div>
  </div>
</template>

<script>
import { getBenefitList, getBenefitURL } from '@/utils/api'
import common from '@/utils/common'
import clipboard from 'clipboard'
export default {
  data() {
    return{
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 10,
      isLoading: false,
      rightsList: [],
      bg: require('@/assets/img/rights/bg.png'),
      bge: require('@/assets/img/rights/bge.png'),
      jdlogo: require('@/assets/img/rights/jdlogo.png'),
      jdlogoe: require('@/assets/img/rights/jdlogoe.png'),
      nodata: require('@/assets/img/nodata/nodata.png'),
      nodatashow: false,
    }
  },
  mounted() {

  },
  methods:{
    togetBenefitURL(benefitId) {
      getBenefitURL({
        token: common.getItem('wtToken'),
        benefitId: benefitId,
      }).then(res=>{
         if (res.code == 200) {
          if (res.data && res.data.data && res.data.businessCode == 200) {
            window.location.href = res.data.data.url
          }
         }
      })
    },
    gotoUse(benefitId) {
      let _this = this
      let clipboardObj = new clipboard('.btn')
      clipboardObj.on('success', function(){
        _this.$toast('复制成功')
        clipboardObj.destroy()
        _this.togetBenefitURL(benefitId)
      })
    },
    getList() {
      getBenefitList({
        phoneNo: common.getItem('phoneNo'),
        token: common.getItem('wtToken'),
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }).then(res=>{
        this.loading = false;
        if (res.code == 200) {
          if (res.data && res.data.data && res.data.businessCode == 200) {
            this.finished = !res.data.data.hasNextPage;
            if (this.pageIndex == 1) {
              this.rightsList = res.data.data.list;
            } else {
              this.rightsList = this.rightsList.concat(res.data.data.list);
            }
            if (!this.finished) {
              this.pageIndex++;
            }

            if(this.rightsList.length == 0){
              this.nodatashow = true
            }

          }
        }
      })
    },
    onRefresh() {
      this.isLoading = false
      this.rightsList = []
      this.pageIndex = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
  }
}
</script>

<style lang="less" scoped>
.rightsAll{
  background: #F5F5F5;
  padding: 0.5rem 0.3rem;
  min-height: 100%;
  .logo-img{
    width: 0.4rem;
    height: 0.4rem;
  }
  .bg-img{
    width: 6.9rem;
  }
  .rights-item{
    position: relative;
    margin-bottom: 0.3rem;
  }
  .no-wrap{
    flex-direction: column;
    align-items: center;
    padding: 0.3rem 1rem;
    color: #616161;
  }
  .rights-content{
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.3rem;
    .title{
      align-content: center;
      div{
        font-size: 0.4rem;
        color: #3A6991;
        margin-left:0.1rem;
      }
      img{
        width: 0.4rem;
        height: 0.4rem;
        margin-top: 0.1rem;
      }
      .title-e{
        color: #7C7C7C;
      }
    }
    .code{
      font-size: 0.24rem;
      color: #85A4BF;
      margin-top: 0.1rem;
    }
    .code-e{
      color: #C0C0C0;
    }
    .date{
      font-size: 0.22rem;
      color: #3A6991;
      margin-top: 0.25rem;
    }
    .date-e{
      color: #7C7C7C;
    }
    .btn{
      margin-top: 0.3rem;
      width: 6.3rem;
      height: 0.74rem;
      background: linear-gradient(270deg, #4178C0 0%, #78ADEB 100%);
      border-radius: 37px;
      font-size: 0.3rem;
      color: #fff;
      border:0;
    }
    .btn-e{
      background: linear-gradient(270deg, #7A7A7A 0%, #D1D1D1 100%);
    }
  }
}
</style>
